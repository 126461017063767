import Siema from 'siema';

// Testimonial Buttons
const testimonialButtons = [...document.querySelectorAll('.testimonial-slide')];

// Create Siema Slider
const testimonialSlider = new Siema({
    selector: '.testimonialSlider',
    easing: 'cubic-bezier(0.075, 0.82, 0.165, 1)',
    duration: 750,
    loop: true,
    onChange: () => {
        const currentIndex = testimonialSlider.currentSlide;
        testimonialButtons.forEach(button => button.classList.remove('-active'));

        const correspondingButton = document.querySelector(
            `.testimonial-slide[data-slide="${currentIndex}"]`
        );

        correspondingButton.classList.add('-active');
    },
});

let sliderInterval = setInterval(() => { testimonialSlider.next() }, 6000);

testimonialButtons.forEach(button => {
    button.addEventListener('click', () => {
        const navIndex = button.dataset.slide * 1;

        testimonialSlider.goTo(navIndex);
    });
});


// Check Window Width before making/destroying Testimonial Slider
window.addEventListener('load', e => {
    if (window.innerWidth <= 576) {
        testimonialSlider.init(true)
    } else {
        testimonialSlider.destroy(true)

        // Set all cards to display inherit when slider is loading/destroying
        const sliderChildren = document.querySelectorAll('.card.-testimonial');

        sliderChildren.forEach(child => {
            child.style.display = 'inherit'
        })

    }
});
window.addEventListener('resize', e => {
    if (window.innerWidth <= 576) {
        testimonialSlider.init(true)
    } else {
        testimonialSlider.destroy(true)


        // Set all cards to display inherit when slider is destroyed
        const sliderChildren = document.querySelectorAll('.card.-testimonial');

        sliderChildren.forEach(child => {
            child.style.display = 'inherit'
        })
    }
});
